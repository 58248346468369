<template>
  <h4>Users</h4>
  <ul>
    <li class="user-list" v-for="user in users" :key="user.userId">

      <div class="grid flex align-items-center">
        <div class="col-10">
          <p class="el-button">{{ user.firstName }} {{user.lastName}}</p>
          <p class="el-caption">{{ user.email }}</p>
          <p class="el-caption">{{ user.phone }}</p>
        </div>
        <div class="col-2">
            <span class="p-button p-component"><i class="fas fa-lock pr-1"></i> Change Password</span>
        </div>
      </div>
              <!-- <pre>{{ user }}</pre>--> 


    </li>
  </ul>


    
</template>

<script>
export default {
  props: ['id'],
  components: {}, //components
  data() {
    return {
      users: null
    };
  }, //data()
  created(){
    this.getData();
  }, //created()
  methods: {
    getData(){
      this.axios.get("/company/" + this.id + "/users").then(response => {
        this.users = response.data;
      });      
    }
  }, //methods

  watch: {
    id(){
      this.getData();
    }
  }
};
</script>

<style></style>
