<template>
  <TabView v-if="company" lazy v-model:activeIndex="activeTab">
    <TabPanel>
      <template #header>
        <span><i class="fas fa-fw fa-info-circle"></i> Info</span>
      </template>
      <company-Form :data="company" />
    </TabPanel>

    <TabPanel>
      <template #header>
        <span><i class="fas fa-fw fa-paper-plane"></i> Applications</span>
      </template>
      <company-applications :id="id"></company-applications>
    </TabPanel>
    
    <TabPanel>
      <template #header>
        <span><i class="fas fa-fw fa-users"></i> Users</span>
      </template>
      <Company-users :id="id" />
    </TabPanel>
  </TabView>
</template>

<script>
import CompanyForm from "@/views/Company/CompanyForm.vue";
import CompanyUsers from "@/views/Company/CompanyUsers.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import CompanyApplications from "./CompanyApplications.vue"
export default {
  components: {
    CompanyForm,
    CompanyUsers,
    CompanyApplications,
    TabView,
    TabPanel
  },

  props: ["id"],

  data() {
    return {
      activeTab: 0,
      company: {},
      loading: false,
      users: [1, 2, 3]
    };
  }, //data()

  created() {
    this.getData();
  }, //created()

  methods: {
    getData() {
      this.axios.get("/company/" + this.id).then(response => (this.company = response.data));
      this.activeTab = 0;
    }
  }, //methods

  watch: {
    id() {
      this.getData();
    }
  }
};
</script>

<style></style>
