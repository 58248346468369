<template>
  <div>
    <div class="grid">
      <div class="col">
        <h4>
          <i class="fas fa-check-circle el-status-approved"></i>
          {{ data.company }}
        </h4>
      </div>
    </div>
    <div class="grid">
      <div class="col">
        <p class="el-caption line-height-3">
          Created: <span class="font-semibold">{{ profile.dateCreated }}</span> | Last Updated:
          <span class="font-semibold">{{ profile.lastUpdate }}</span>
        </p>
      </div>
    </div>

    <div class="limitWidth">
      <form>
        <div class="card">
          <!---Primary Info---->
          <Divider align="left" class="divider-feature">
            <div class="p-d-inline-flex p-ai-center">Company Information</div>
          </Divider>

          <div class="formgrid grid py-3">
            <div class="field col-8">
              <div class="p-float-label p-fluid">
                <InputText id="company" v-model="profile.company" />
                <label for="company">Company Name*</label>
              </div>
            </div>
          </div>
          <div class="formgrid grid py-3">
            <div class="field col-8">
              <div class="p-float-label p-fluid">
                <InputText id="address" v-model="profile.address" />
                <label for="address">Company Address*</label>
              </div>
            </div>
            <div class="field col-4">
              <div class="p-float-label p-fluid">
                <InputText id="address2" v-model="profile.address2" />
                <label for="address2">Unit / Suite</label>
              </div>
            </div>
          </div>
          <div class="formgrid grid py-3">
            <div class="field col-8">
              <div class="p-float-label p-fluid">
                <InputText id="operationsAddress" v-model="profile.operationsAddress" />
                <label for="operationsAddress">Operations Address (if Different)</label>
              </div>
            </div>
            <div class="field col-4">
              <div class="p-float-label p-fluid">
                <InputText id="operationsAddress2" v-model="profile.operationsAddress2" />
                <label for="operationsAddress2">Unit / Suite</label>
              </div>
            </div>
          </div>
          <div class="formgrid grid py-3">
            <div class="field col-8">
              <div class="p-float-label p-fluid">
                <Textarea id="notes" v-model="profile.notes" rows="3" cols="30" />
                <label for="notes">Notes</label>
              </div>
            </div>
          </div>
        </div>

        <!---Legal Info---->
        <div class="card mt-3">
          <Divider align="left" class="divider-feature">
            <div class="p-d-inline-flex p-ai-center">Legal</div>
          </Divider>
          <div class="formgrid grid py-3">
            <div class="field col-5">
              <div class="p-float-label p-fluid">
                <InputText id="incorporationNumber" v-model="profile.incorporationNumber" />
                <label for="incorporationNumber"
                  >Federal / State Incorporation Identification Number</label
                >
              </div>
            </div>
          </div>
          <div class="formgrid grid py-3">
            <div class="field col-5">
              <div class="p-float-label p-fluid">
                <InputText id="bl" v-model="profile.bl" />
                <label for="bl">Business Licence Number</label>
              </div>
            </div>
            <div class="field col-2">
              <div class="p-float-label p-fluid">
                <Calendar id="blExp" v-model="profile.blExp" />
                <label for="blExp">Business Licence Expiry</label>
              </div>
            </div>
          </div>
        </div>
        <!---Manufacturer Info--->
        <div class="card mt-3">
          <Divider align="left" class="divider-feature">
            <div class="p-d-inline-flex p-ai-center">Manufacturer</div>
          </Divider>
          <div class="grid">
            <div class="col ml-2">
              <div class="formgroup-inline ">
                <span class="el-caption mr-3 mt-1">Is a manufacturer?</span>
                <div class="field-radiobutton">
                  <RadioButton
                    name="manufacturer_true"
                    value="1"
                    v-model="profile.isManufacturer"
                  />
                  <label for="manufacturer_true">Yes</label>
                </div>
                <div class="field-radiobutton">
                  <RadioButton
                    name="manufacturer_false"
                    value="0"
                    v-model="profile.isManufacturer"
                  />
                  <label for="manufacturer_false">No</label>
                </div>
              </div>
            </div>
          </div>

          <transition name="fade" mode="out-in">
            <section v-if="Number(profile.isManufacturer)">
              <div class="formgrid grid py-3">
                <div class="field col-5">
                  <div class="p-float-label p-fluid">
                    <InputText id="ml" v-model="profile.ml" />
                    <label for="ml">Manufacturer Licence Number</label>
                  </div>
                </div>
                <div class="field col-2">
                  <div class="p-float-label p-fluid">
                    <Calendar id="mlExp" v-model="profile.mlExp" />
                    <label for="mlExp">Licence Expiry</label>
                  </div>
                </div>
              </div>
            </section>
          </transition>
        </div>

        <div class="card mt-3">
          <!---Chamber Info--->
          <Divider align="left" class="divider-feature">
            <div class="p-d-inline-flex p-ai-center">Chamber &amp; Affiliations</div>
          </Divider>

          <div class="formgroup-inline ml-2">
            <span class="el-caption mr-3 mt-1">Is chamber member?</span>
            <div class="field-radiobutton">
              <RadioButton name="cocMember_true" value="1" v-model="profile.member" />
              <label for="cocMember_true">Yes</label>
            </div>
            <div class="field-radiobutton">
              <RadioButton name="cocMember_false" value="0" v-model="profile.member" />
              <label for="cocMember_false">No</label>
            </div>
          </div>

          <transition name="fade" mode="out-in">
            <section v-if="Number(profile.member)">
              <div class="formgrid grid py-3">
                <div class="field col-5">
                  <div class="p-float-label p-fluid">
                    <InputText id="membershipNumber" v-model="profile.membershipNumber" />
                    <label for="membershipNumber">Chamber Membership Number</label>
                  </div>
                </div>
                <div class="field col-2">
                  <div class="p-float-label p-fluid">
                    <Calendar id="cocExp" v-model="profile.cocExp" />
                    <label for="cocExp">Membership Expiry</label>
                  </div>
                </div>
              </div>
            </section>
          </transition>

          <div class="p-field-checkbox my-2">
            <Checkbox id="lacbffa" :binary="true" v-model="profile.lacbffa" />
            <label class="pl-2" for="lacbffa"
              >Los Angeles Customs Brokers and Freight Forwarders Association</label
            >
          </div>
          <div class="p-field-checkbox my-2">
            <Checkbox id="wtcm" :binary="true" v-model="profile.wtcm" />
            <label class="pl-2" for="wtcm">World Trade Center Miami</label>
          </div>
          <div class="p-field-checkbox my-2">
            <Checkbox id="ta" :binary="true" v-model="profile.ta" />
            <label class="pl-2" for="ta">California Trade Association</label>
          </div>
          <div class="p-field-checkbox my-2">
            <Checkbox id="escoc" :binary="true" v-model="profile.escoc" />
            <label class="pl-2" for="escoc">El Segundo Chamber of Commerce</label>
          </div>
        </div>
        <!---Contacts--->
        <div class="card mt-3">
          <Divider align="left" class="divider-feature">
            <div class="p-d-inline-flex p-ai-center">Primary Contact</div>
          </Divider>
          <div class="formgrid grid py-3">
            <div class="field col-4">
              <div class="p-float-label p-fluid">
                <InputText id="firstName" v-model="profile.firstName" />
                <label for="firstName">First Name*</label>
              </div>
            </div>
            <div class="field col-4">
              <div class="p-float-label p-fluid">
                <InputText id="lastName" v-model="profile.lastName" />
                <label for="lastName">Last Name*</label>
              </div>
            </div>
            <div class="field col-4">
              <div class="p-float-label p-fluid">
                <InputText id="title" v-model="profile.title" />
                <label for="title">Title</label>
              </div>
            </div>
          </div>
          <div class="formgrid grid py-3">
            <div class="field col-4">
              <div class="p-float-label p-fluid">
                <InputText id="email" v-model="profile.email" />
                <label for="email">Email*</label>
              </div>
            </div>
            <div class="field col-4">
              <div class="p-float-label p-fluid">
                <InputText id="phone" v-model="profile.phone" />
                <label for="phone">Phone*</label>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <Divider align="left" class="divider-feature">
            <div class="p-d-inline-flex p-ai-center">Secondary Contact</div>
          </Divider>
          <div class="formgrid grid py-3">
            <div class="field col-4">
              <div class="p-float-label p-fluid">
                <InputText id="firstName2" v-model="profile.firstName2" />
                <label for="firstName2">First Name</label>
              </div>
            </div>
            <div class="field col-4">
              <div class="p-float-label p-fluid">
                <InputText id="lastName2" v-model="profile.lastName2" />
                <label for="lastName2">Last Name</label>
              </div>
            </div>
            <div class="field col-4">
              <div class="p-float-label p-fluid">
                <InputText id="title2" v-model="profile.title2" />
                <label for="title2">Title</label>
              </div>
            </div>
          </div>
          <div class="formgrid grid py-3">
            <div class="field col-4">
              <div class="p-float-label p-fluid">
                <InputText id="email2" v-model="profile.email2" />
                <label for="email2">Email</label>
              </div>
            </div>
            <div class="field col-4">
              <div class="p-float-label p-fluid">
                <InputText id="phone2" v-model="profile.phone2" />
                <label for="phone2">Phone</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Divider from "primevue/divider";
import Calendar from "primevue/calendar";
import Textarea from "primevue/textarea";
import RadioButton from "primevue/radiobutton";
import Checkbox from "primevue/checkbox";

export default {
  props: ["data"],
  components: {
    Divider,
    Calendar,
    Textarea,
    RadioButton,
    Checkbox
  }, //components
  data() {
    return {
      profile: false,
      affiliates: ["lacbffa", "wtcm", "ta", "escoc"]
    };
  }, //data()
  methods() {},
  created() {
    this.profile = this.data;
  },
  watch: {
    data() {
      this.profile = this.data;
    }
  }
};
</script>

<style></style>
