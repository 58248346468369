<template>
  <div class="contentWrapper">
    <div class="sideListContainer">
      <DataTable
        :value="profiles"
        :paginator="true"
        :rows="50"
        responsiveLayout="scroll"
        class="p-datatable-customers"
        dataKey="id"
        :removableSort="true"
        :scrollable="true"
        scrollHeight="flex"
        :loading="loading"
        v-model:filters="filter"
        :globalFilterFields="['company']"
        :rowClass="rowClass"
        @row-click="selectRow"
      >
        <template #loading>Loading Companies...</template>

        <template #header>
          <div class="sideTools">
            <div class="sideToolsTitle">
              <h6 class="mt-2 ml-2">
                <i class="fas fa-fw fa-city fa-fw fa-sm" aria-hidden="true"></i> Companies
              </h6>
            </div>

            <div class=" w-full">
              <span class="p-input-icon-left w-full ">
                <i class="fa fa-search" />
                <InputText
                  v-model="filter['global'].value"
                  placeholder="Search"
                  :class="'fullWidthInput'"
                  type="search"
                />
              </span>
            </div>
            <div>
              <button
                class="p-button p-component p-button-icon-only p-button-rounded-light ml-2"
                type="button"
                aria-haspopup="true"
                aria-controls="overlay_menu"
              >
                <span class="fa fa-filter p-button-icon" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </template>

        <Column field="company" header="Company" :sortable="true" :class="'company-col'"> </Column>
        <Column header="Chamber" :sortable="true" :class="'icon-col'">
          <template #body="{data}">
            <span class=" chip-chamber" :class="'chip-' + data.chamber">{{ data.chamber }}</span>
          </template>
        </Column>

        <Column field="status" header="Status" :sortable="true" :class="'icon-col'">
          <template #body="{data}">
            <i :class="statusIcon(data.status)"></i>
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="mainContent">
      <div class="card">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import { FilterMatchMode } from "primevue/api";

export default {
  components: {
    DataTable,
    Column
  },
  data() {
    return {
      profiles: null,
      loading: false,
      filter: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      }
    };
  },
  created() {},
  mounted() {
    this.loading = true;
    this.axios.get("/company").then(response => {
      this.profiles = response.data;
      this.loading = false;

      if (this.$route.params.id) {
        this.$router.push("/company/" + this.$route.params.id);
      } else {
        this.$router.push("/company/" + this.profiles[0].id);
      }
    });
  },
  methods: {
    rowClass(data) {
      return data.id === this.$route.params.id ? "selected" : "";
    }, //rowClass()
    selectRow(e) {
      this.$router.push("/company/" + e.data.id);
    }, //selectrow()
    statusIcon(status) {
      if (status === "Approved") return "fas fa-check-circle el-status-approved";
      else if (status === "Declined") return "fas fa-times-circle el-status-declined";
      else return "fas fa-hourglass-half el-status-pending";
    } //statusIcon()
  }, //methods
  computed: {}
};
</script>
